


import Loader from 'components/loader';
import React, { useEffect, useRef, useState } from "react";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import { Tooltip } from "react-tooltip";

const GuidelineOutput = ({
  divHeight,
  analysisResult,
  showOutput,
  isLgScreen,
  loading,
  error,
}) => {
  const outputRef = useRef(null);
  const complianceScore =
    parseFloat(analysisResult?.result?.compliance_score) || 0;
  const [progressValue, setProgressValue] = useState(0);
  const [copySuccess, setCopySuccess] = useState("Copy");
  const speed = 2;
  useEffect(() => {
    setProgressValue(0);
  }, [analysisResult]);

  useEffect(() => {
    if (progressValue >= complianceScore) return;

    const progressbar = document.querySelector(".circular-progress");
    const valueContainer = document.querySelector(".value-container");

    if (progressbar && valueContainer) {
      const interval = setInterval(() => {
        setProgressValue((prevValue) => {
          const newValue = Math.min(prevValue + 1, complianceScore);
          valueContainer.textContent = `${newValue}%`;

          progressbar.style.background = `conic-gradient(#4d5bf9 ${
            newValue * 3.6
          }deg, #cadcff ${newValue * 3.6}deg)`;

          if (newValue === complianceScore) {
            clearInterval(interval);
          }

          return newValue;
        });
      }, speed);

      return () => clearInterval(interval);
    } else {
      console.error("Elements not found in the DOM.");
    }
  }, [complianceScore, progressValue, speed]);

  const renderFindings = (type) => {
    return analysisResult?.result?.findings.map((finding, index) => (
      <p
        key={index}
        className={`text-base font-opensans rounded-md text-[#575757] p-1 mt-[18px] ${
          type === "deviation"
            ? "border border-[#F99] bg-[#FFDBDB]"
            : "border border-[#B8EABA] bg-[#DBF5DC]"
        }`}
      >
        {type === "deviation" ? finding.deviation : finding.suggestion}
      </p>
    ));
  };
  const copyText = () => {
    if (outputRef.current) {
      const textToCopy = outputRef.current.innerText;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopySuccess("Copied!");
          setTimeout(() => setCopySuccess("Copy"), 2000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  const downloadCSV = () => {
    const results = analysisResult.result;

    const csvRows = [];

    csvRows.push(["Type", "Value"]);

    csvRows.push(["Compliance Score", results.compliance_score]);

    results.findings.forEach((finding) => {
      if (finding.deviation) {
        csvRows.push(["Deviation", finding.deviation]);
      }
      if (finding.suggestion) {
        csvRows.push(["Suggestion", finding.suggestion]);
      }
    });

    const csvString = csvRows.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "analysis_results.csv");

    document.body.appendChild(link);
    link.click();

    link.remove();
    URL.revokeObjectURL(url);
  };

  return (
    <div
      style={{ minHeight: divHeight ? `${divHeight}px` : `330px` }}
      className={` ${
        analysisResult ? "bg-[#ffffff]" : ""
      } mt-6 rounded-lg shadow-lg p-4`}
    >
      <div className="flex justify-between">
        <p className="text-[#6E6E6E] font-opensans font-bold text-base">
          Output
        </p>
        {analysisResult?.result && (
          <>
            <div className="flex gap-4 ">
              <button
                data-tooltip-id="copy-tooltip"
                data-tooltip-content={copySuccess}
                onClick={copyText}
                className=""
              >
                <img src={CopyIcon} alt="/copyicons" />
              </button>
              <button
                data-tooltip-id="download-tooltip"
                data-tooltip-content="Download"
                onClick={downloadCSV}
                className=""
              >
                <img src={DownloadIcon} alt="/downloadicon" />
              </button>
            </div>
            <Tooltip
              id="copy-tooltip"
              place="top"
              multiline={true}
              className="z-50"
            />
            <Tooltip
              id="download-tooltip"
              place="top"
              multiline={true}
              className="z-50"
            />
          </>
        )}
      </div>
      {analysisResult ? (
      <div
        style={{ minHeight: `${divHeight}px` }}
        className="flex flex-col  h-full  items-center justify-center"
        ref={outputRef}
      >
        {loading ? (
          <div className="h-[250px]  flex flex-col items-center justify-center">
            <Loader />
            <p className="text-center font-opensans leading-6 text-sm mt-4">
              We're analyzing the translation and source text.
              <br />
              Please wait a few seconds for the results.
            </p>
          </div>
        ) : error && analysisResult?.result === "null" ? (
          <div className="flex flex-col items-center justify-center font-opensans text-[#FF6961] leading-6 text-sm text-red-500">
            <svg
              class="h-8 w-8 text-red-500"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              {" "}
              <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />{" "}
              <line x1="12" y1="9" x2="12" y2="13" />{" "}
              <line x1="12" y1="17" x2="12.01" y2="17" />
            </svg>

            <p className="text-center">
              Please check your input and try again.
            </p>
          </div>
        ) : showOutput ? (
          <>
            <div className="lg:flex gap-3 mt-4 w-full h-full ">
              <div className="flex flex-col gap-3 w-full lg:w-[70%]  ">
                {complianceScore === 100 ? (
                  <div
                    // style={{
                    //   minHeight: isLgScreen ? `${divHeight + 30}px` : "230px",
                    // }}
                    className="bg-[#EFFAF0] h-full w-full rounded-lg flex flex-col items-center justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="84"
                      height="80"
                      viewBox="0 0 84 80"
                      fill="none"
                    >
                      <path
                        d="M0 79.832V70.5379H56V79.832H0ZM0 42.6556V33.3615H29.4C29.9444 35.0654 30.5667 36.6918 31.2667 38.2409C31.9667 39.7899 32.8222 41.2615 33.8333 42.6556H0ZM0 61.2438V51.9497H43.8666C45.6555 53.034 47.5611 53.9448 49.5833 54.6822C51.6055 55.4195 53.7444 55.9415 56 56.2482V61.2438H0Z"
                        fill="#98E19B"
                      />
                      <path
                        d="M57.4 34.5232L73.85 18.0261L70.5834 14.7732L57.4 27.9011L50.75 21.2791L47.4834 24.6482L57.4 34.5232ZM60.6667 47.3026C54.2111 47.3026 48.7092 45.0364 44.1607 40.504C39.6123 35.9715 37.3365 30.4927 37.3334 24.0673C37.3303 17.642 39.606 12.1631 44.1607 7.63068C48.7154 3.09825 54.2174 0.832031 60.6667 0.832031C67.116 0.832031 72.6196 3.09825 77.1774 7.63068C81.7351 12.1631 84.0094 17.642 84 24.0673C83.9907 30.4927 81.7149 35.9731 77.1727 40.5086C72.6305 45.0442 67.1285 47.3088 60.6667 47.3026Z"
                        fill="#75D778"
                      />
                    </svg>
                    <p className="text-center text-[#575757] pt-5 font-opensans leading-6 text-sm">
                      No deviations found.
                    </p>
                  </div>
                ) : complianceScore > 1 && complianceScore < 99 ? (
                  <div className=" w-full h-full">
                    <div className="lg:flex items-center h-full rounded-lg shadow-lg w-full">
                      <div
                        style={{
                          minHeight: isLgScreen ? `${divHeight}px` : "230px",
                        }}
                        className="bg-[#FFF0F0] h-full w-full  rounded-lg p-3"
                      >
                        <h2 className="text-[#8A0000] font-opensans text-semibold">
                          Deviation
                        </h2>
                        {renderFindings("deviation")}
                      </div>
                      <div
                        style={{
                          minHeight: isLgScreen
                            ? `${divHeight + 17}px`
                            : "230px",
                        }}
                        className="bg-[#EFFAF0] w-full h-full rounded-lg p-3"
                      >
                        <h2 className="text-[#19571B] font-opensans text-semibold">
                          Suggestion for Improvement
                        </h2>
                        {renderFindings("suggestion")}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center font-opensans text-[#FF6961] leading-6 text-sm text-red-500">
                    <svg
                      className="h-8 w-8 text-red-500"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                      <line x1="12" y1="9" x2="12" y2="13" />
                      <line x1="12" y1="17" x2="12.01" y2="17" />
                    </svg>
                    <p className="text-center">
                      Please check your input and try again.
                    </p>
                  </div>
                )}
              </div>
              <div
                style={{
                  minHeight: isLgScreen ? `${divHeight}px` : "230px",
                }}
                className="bg-[#F0F5FF] w-full lg:w-[30%]  rounded-lg p-3 mt-5 lg:mt-0"
              >
                <h2 className="text-[#002E8A] font-opensans text-semibold ">
                  Compliance Score
                </h2>
                <div className="progress-container flex items-center justify-center mt-4">
                  <div className="circular-progress">
                    <span className="value-container relative text-2xl font-bold  text-[#5B93FF]"></span>
                  </div>
                </div>
              </div>
            </div>

           
          </>
        ) : (
          <div className="h-[250px]  flex flex-col items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="70"
              viewBox="0 0 80 70"
              fill="none"
            >
              <path
                d="M72.5 69.8838H7.5C3.35781 69.8838 0 66.5371 0 62.4087V7.59143C0 3.46301 3.35781 0.116348 7.5 0.116348H72.5C76.6422 0.116348 80 3.46301 80 7.59143V62.4087C80 66.5371 76.6422 69.8838 72.5 69.8838ZM71.5625 7.59143H8.4375C8.18886 7.59143 7.9504 7.68988 7.77459 7.86511C7.59877 8.04034 7.5 8.278 7.5 8.52582V61.4743C7.5 61.7221 7.59877 61.9598 7.77459 62.135C7.9504 62.3103 8.18886 62.4087 8.4375 62.4087H71.5625C71.8111 62.4087 72.0496 62.3103 72.2254 62.135C72.4012 61.9598 72.5 61.7221 72.5 61.4743V8.52582C72.5 8.278 72.4012 8.04034 72.2254 7.86511C72.0496 7.68988 71.8111 7.59143 71.5625 7.59143ZM65 21.9187V18.1811C65 17.1491 64.1605 16.3124 63.125 16.3124H31.875C30.8395 16.3124 30 17.1491 30 18.1811V21.9187C30 22.9507 30.8395 23.7874 31.875 23.7874H63.125C64.1605 23.7874 65 22.9507 65 21.9187ZM65 36.8688V33.1313C65 32.0993 64.1605 31.2625 63.125 31.2625H31.875C30.8395 31.2625 30 32.0993 30 33.1313V36.8688C30 37.9009 30.8395 38.7376 31.875 38.7376H63.125C64.1605 38.7376 65 37.9009 65 36.8688ZM65 51.819V48.0815C65 47.0494 64.1605 46.2127 63.125 46.2127H31.875C30.8395 46.2127 30 47.0494 30 48.0815V51.819C30 52.851 30.8395 53.6878 31.875 53.6878H63.125C64.1605 53.6878 65 52.851 65 51.819ZM25.625 49.9502C25.625 46.854 23.1066 44.3439 20 44.3439C16.8934 44.3439 14.375 46.854 14.375 49.9502C14.375 53.0465 16.8934 55.5565 20 55.5565C23.1066 55.5565 25.625 53.0465 25.625 49.9502ZM25.625 35.0001C25.625 31.9038 23.1066 29.3938 20 29.3938C16.8934 29.3938 14.375 31.9038 14.375 35.0001C14.375 38.0963 16.8934 40.6064 20 40.6064C23.1066 40.6064 25.625 38.0963 25.625 35.0001ZM25.625 20.0499C25.625 16.9537 23.1066 14.4436 20 14.4436C16.8934 14.4436 14.375 16.9537 14.375 20.0499C14.375 23.1461 16.8934 25.6562 20 25.6562C23.1066 25.6562 25.625 23.1461 25.625 20.0499Z"
                fill="#C7C7C7"
              />
            </svg>
            {/* <p className="text-center font-opensans leading-6 text-sm" dangerouslySetInnerHTML={{__html:text}} /> */}
            <p className="text-center text-[#575757] pt-5 font-opensans leading-6 text-sm">
              Your results will appear here. <br />
              Please enter your texts and click “Evaluate Text” to begin.
            </p>
          </div>
        )}
      </div>
      ): (
        <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
        { error?.message}
      </span>
      )}
    </div>
  );
};

export default GuidelineOutput



